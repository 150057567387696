const APP_ENV = "DEV"; // DEV, DEMO, PROD
const APP_EXTENSION = ".com";
const MAINTENANCE_MODE = false;

const config = {
  ENV: APP_ENV,
  IS_MAINTENANCE_MODE: MAINTENANCE_MODE,
  EXTENSION: APP_EXTENSION,
  REACT_APP_GOOGLE_ANALYTICAL_CODE: "UA-185021427-1",
  DOMAIN_GROUP_ID_COOKIE_BOT: "38f8267b-57ea-48fd-9a79-4b8f9186667e",
  GOOGLE_TAG_ID: "GTM-TCFDCLX",

  APP_URL: "https://truecostlabel.com/",
  API_URL: "https://app.services.truecostlabel.com",
};

if (APP_ENV === "DEV") {
  config.APP_URL = "http://dev.truecostlabel.com/";
  config.API_URL = "https://dev.services.truecostlabel.com";
}

if (APP_ENV === "DEMO") {
  config.APP_URL = "http://demo.truecostlabel.com/";
  config.API_URL = "https://demo.services.truecostlabel.com";
}

export const packageConfigure = {
  APP_URL: config.APP_URL,
  APP_ENV: config.ENV,
  Extension: config.EXTENSION,
};

export default config;
