/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:6add7382-7af8-43aa-9b49-9b551151110e",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_ivPyjqT1q",
    "aws_user_pools_web_client_id": "3vvon05g3v18q6bpmb6i7g07c",
    "oauth": {}
};


export default awsmobile;
